import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edc-mon',
  templateUrl: './edc-mon.component.html',
  styleUrls: ['./edc-mon.component.css']
})
export class EdcMonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
