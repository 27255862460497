import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trim',
  templateUrl: './trim.component.html',
  styleUrls: ['./trim.component.css']
})
export class TrimComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
