import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ExpertiseComponent } from './expertise/expertise.component';
import { ProjectComponent } from './project/project.component';
import { ContactusComponent } from './contactus/contactus.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { ProductComponent } from './product/product.component';
import { TrimComponent } from './product/trim/trim.component';
import { EdcComponent } from './product/edc/edc.component';
import { AtmMonComponent } from './product/atm-mon/atm-mon.component';
import { EdcMonComponent } from './product/edc-mon/edc-mon.component';
import { PaymentPurchaseComponent } from './product/payment-purchase/payment-purchase.component';
import { WebBranchComponent } from './product/web-branch/web-branch.component';
import { ClientComponent } from './product/client/client.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ExpertiseComponent,
    ProjectComponent,
    ContactusComponent,
    NavigationComponent,
    FooterComponent,
    ProductComponent,
    TrimComponent,
    EdcComponent,
    AtmMonComponent,
    EdcMonComponent,
    PaymentPurchaseComponent,
    WebBranchComponent,
    ClientComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
