import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    var num = 50; //number of pixels before modifying styles

    // $(window).bind('scroll', function () {
    //   console.log($(window).scrollTop());

    //   if ($(window).scrollTop() > num) {
    //     $('#menu').addClass('fixed');
    //   } else {
    //     $('#menu').removeClass('fixed');
    //   }
    // });

    $(window).scroll(function () {
      if ($(window).scrollTop() >= $(".navbar").height()) {
        // console.log(true);
        $('nav').addClass('fixed-header')
        $('.navbar-toggler').addClass('active')
        $('.navbar-toggler-icon').addClass('togle-change')
        $('.nav-link').addClass('router-link-active2')
      } else {
        // console.log(false);
        $('nav').removeClass('fixed-header')
        $('.navbar-toggler').removeClass('active')
        $('.navbar-toggler-icon').removeClass('togle-change')
        $('.nav-link').removeClass('router-link-active2')
      }
    });

    $(document).ready(function () {
      $(".navbar-toggler").on("click", function () {
        console.log($(this).toggleClass("active"));

        $(this).toggleClass("active");
      });
    });


  }

}
