import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edc',
  templateUrl: './edc.component.html',
  styleUrls: ['./edc.component.css']
})
export class EdcComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
